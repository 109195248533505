import { useTranslation } from 'react-i18next';
import QuantityStepper from '@ingka/quantity-stepper';
import { useState } from 'react';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { TextLabel } from './TextLabel';
import { useSidebarContext } from '../SidebarContext/SidebarContext';

export function QuantityGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const [keyboardInput, setKeyboardInput] = useState<null | string>(null);
  const {
    setQuantityDraft,
    quantityDraft,
  } = useSidebarContext();
  const parseQuantityInput = (input: string | null): number => {
    if (input === '') {
      // The QuantityStepper's "value" prop is typed
      // as option key with a number value, however, we need to pass an empty string
      //  when the input is empty
      return '' as unknown as number;
    }

    if (input === null) {
      return Number(quantityDraft?.quantity ?? item.quantity);
    }
    const inputNumber = Number(input);

    if (inputNumber > item.maxQuantity) {
      return item.maxQuantity;
    }
    if (inputNumber === 0) {
      return 1;
    }

    return inputNumber;
  };
  const changeQuantityValue = quantityDraft?.quantity ?? item.quantity;
  const itemRef = {
    popId: item.popId,
    itemLineId: item.itemLineId,
  };
  const onQuantityChange = (value: number) => () => setQuantityDraft({
    itemRef,
    quantity: value,
  });
  return (
    <div>
      <TextLabel
        className="mb-3"
        text={t('otc-article-table.table-head-columns.quantity')}
      />
      <QuantityStepper
        ariaDescribedBy={
        t('otc-article-table.quantity-stepper.ariaDescription', { ariaProductName: item.name })
      }
        ariaDescribedById={`quantity-stepper-helper-${item.itemLineId}`}
        ariaLabelIncrease={t('otc-article-table.quantity-stepper.ariaIncrease')}
        ariaLabelDecrease={t('otc-article-table.quantity-stepper.ariaDecrease')}
        ariaLabelInput={t('otc-article-table.quantity-stepper.ariaInput')}
        className="
        [&.quantity-stepper__input:disabled]:text-lightest
        [&.quantity-stepper__input]:p-0
        [&_.quantity-stepper\_\_increase]:my-[2px]
      "
        data-testid="quantity-stepper"
        fluid={false}
        maxVal={item.maxQuantity}
        minVal={keyboardInput === '' ? 0 : 1}
        small
        value={parseQuantityInput(keyboardInput)}
        onDecrease={onQuantityChange(changeQuantityValue - 1)}
        onIncrease={onQuantityChange(changeQuantityValue + 1)}
        onBlur={() => {
          if (keyboardInput !== null) {
            setQuantityDraft({
              itemRef,
              quantity: parseQuantityInput(keyboardInput),
            });
            setKeyboardInput(null);
          }
        }}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setKeyboardInput(e.target.value);
        }}
      />
    </div>
  );
}
