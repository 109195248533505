import { useEffect } from 'react';
import Modal, { Sheets } from '@ingka/modal';
import { useCloseSidebar, useGetSidebarState } from '../../store/features/sidebar/sidebarSlice';
import SidebarRouter from './SidebarRouter';
import { SidebarContextProvider } from './SidebarContext/SidebarContext';
import { useOTCItemDetailsSaveChanges } from './OTCItemDetails/useOTCItemDetailsSaveChanges';

const useRecaptureFocus = (isSidebarOpen: boolean) => {
  const sheetId = 'lamna-sidebar-sheet';
  useEffect(() => {
    const { activeElement } = document;
    if (isSidebarOpen && activeElement?.localName === 'body') {
      const sheet = document.getElementById(sheetId);
      const sheetBody = sheet?.children[1] as HTMLElement;
      sheetBody?.focus();
    }
  }, [document.activeElement, isSidebarOpen]);
  return sheetId;
};

function RenderSideBar({
  isOpen,
  sideBarPage,
  recaptureFocusId,
}: {
  isOpen: boolean;
  sideBarPage: string;
  recaptureFocusId: string;
}) {
  const close = useCloseSidebar();
  const OTCItemDetailsOnClose = useOTCItemDetailsSaveChanges();
  const onClose = () => {
    OTCItemDetailsOnClose();
    close();
  };
  return (
    <Modal
      data-testid="sidebar-modal"
      escapable={sideBarPage === 'more-menu'}
      handleCloseBtn={onClose}
      visible={isOpen}
    >
      <Sheets
        footer={SidebarRouter.Footer()}
        header={SidebarRouter.Header()}
        id={recaptureFocusId}
        labelledById={`${sideBarPage}-modalLabelId`}
      >
        <SidebarRouter.Body />
      </Sheets>
    </Modal>
  );
}

export function Sidebar() {
  const isOpen = useGetSidebarState('open');
  const sideBarPage = useGetSidebarState('page');
  const recaptureFocusId = useRecaptureFocus(isOpen);
  const sidebarProps = {
    isOpen,
    sideBarPage,
    recaptureFocusId,
  };
  return (
    <SidebarRouter>
      <SidebarContextProvider>
        <RenderSideBar
          {...sidebarProps}
        />
      </SidebarContextProvider>
    </SidebarRouter>
  );
}
