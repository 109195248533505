import Button from '@ingka/button';
import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { useCloseSidebar } from '../../store/features/sidebar/sidebarSlice';
import { useOTCItemDetailsSaveChanges } from './OTCItemDetails/useOTCItemDetailsSaveChanges';

export function SidebarCloseFooter() {
  const { t } = useTranslation();
  const closeSideBar = useCloseSidebar();
  const OTCItemDetailsOnClose = useOTCItemDetailsSaveChanges();
  const onCloseHandler = () => {
    OTCItemDetailsOnClose();
    closeSideBar();
  };

  return (
    <ModalFooter>
      <Button
        text={t('commonly-reused.close')}
        onClick={onCloseHandler}
      />
    </ModalFooter>
  );
}
