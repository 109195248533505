import {
  createContext, useState, useMemo, useContext,
} from 'react';
import { SetPriceAndMessagePayload, UpdateItemQuantityPayload } from '../../../store/features/OTCSlice/reducerUtils';
import { SidebarPageKey } from '../../../store/features/standAloneActions/extraReducersActions';

export const sidebarPagesWithContext: SidebarPageKey[] = ['otc-item-details'];

export type OTCItemDetailsContextType = {
  priceDraft: SetPriceAndMessagePayload | null;
  setPriceDraft: (payload: SetPriceAndMessagePayload) => void;
  quantityDraft: UpdateItemQuantityPayload | null;
  setQuantityDraft: (payload: UpdateItemQuantityPayload) => void;
};

export type SidebarContextType = OTCItemDetailsContextType & {
  clearContext: () => void;
};

const initialState: SidebarContextType = {
  priceDraft: null,
  quantityDraft: null,
  setPriceDraft: () => {},
  setQuantityDraft: () => {},
  clearContext: () => {},
};

export const SidebarContext = createContext<SidebarContextType>(initialState);
function useOTCItemDetailsState() {
  const [priceDraft, setPriceDraft] = useState<SetPriceAndMessagePayload | null>(null);
  const [quantityDraft, setQuantityDraft] = useState<UpdateItemQuantityPayload | null>(null);
  const clearOTCItemDetailsState = () => {
    setPriceDraft(initialState.priceDraft);
    setQuantityDraft(initialState.quantityDraft);
  };

  return {
    priceDraft,
    setPriceDraft,
    quantityDraft,
    setQuantityDraft,
    clearOTCItemDetailsState,
  } as const;
}

export function SidebarContextProvider({ children }:{ children: React.ReactNode }) {
  const {
    priceDraft,
    setPriceDraft,
    quantityDraft,
    setQuantityDraft,
    clearOTCItemDetailsState,
  } = useOTCItemDetailsState();

  const clearContext = () => {
    clearOTCItemDetailsState();
  };

  const contextValue = useMemo(() => ({
    priceDraft,
    setPriceDraft,
    quantityDraft,
    setQuantityDraft,
    clearContext,
  }), [priceDraft, setPriceDraft, quantityDraft, setQuantityDraft, clearContext]);

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
}
export const useSidebarContext = () => useContext(SidebarContext);
