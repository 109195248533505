import { useSetArticlePrice, useChangeItemsQuantityForArticle } from '../../../store/features/OTCSlice/OTCSlice';
import { useCloseSidebar, useGetSidebarState } from '../../../store/features/sidebar/sidebarSlice';
import { useSidebarContext } from '../SidebarContext/SidebarContext';

export const useOTCItemDetailsSaveChanges = () => {
  const pageIsOTCItemDetails = useGetSidebarState('page') === 'otc-item-details';
  const sidebarContext = useSidebarContext();
  const dispatchChangePrice = useSetArticlePrice();
  const dispatchChangeQuantity = useChangeItemsQuantityForArticle();
  const closeSideBar = useCloseSidebar();
  const onCloseHandler = () => {
    const { priceDraft: changePrice, quantityDraft: changeQuantity, clearContext } = sidebarContext;
    if (changePrice) {
      dispatchChangePrice(changePrice);
    }
    if (changeQuantity) {
      const { quantity } = changeQuantity;
      const isPop = 'itemRef' in changeQuantity;
      const itemLineId = isPop ? changeQuantity.itemRef.itemLineId : changeQuantity.itemLineId;
      const popId = isPop ? changeQuantity.itemRef.popId : null;
      dispatchChangeQuantity(popId, itemLineId, quantity);
    }
    if (changePrice || changeQuantity) clearContext();
    closeSideBar();
  };
  return pageIsOTCItemDetails ? onCloseHandler : () => {};
};
